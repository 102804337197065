import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useRouter } from 'vue-router'

const INIT_PAGE = 0
const INIT_LIMIT = 20
const INIT_TOTAL_CNT = 0

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const state = reactive({
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    totalCnt: INIT_TOTAL_CNT,
    list: [],
    initCnt: 0,
    params: computed({
      get: () => {
        return {
          startNum: state.list.length + 1,
          endNum: (state.page + 1) * state.limit
        }
      }
    }),
    timer: undefined
  })

  const fnListFunction = async () => {
    const res = await proxy.$CustomerSvc.postMyQuestionList(state.params)
    if (res.resultCode === '0000') {
      state.page++
      state.totalCnt = res.totalCnt
      state.list = state.list.concat(
        res.list.map(item => {
          return {
            ...item,
            isShow: false
          }
        })
      )
    }
  }

  const fnWrite = async () => {
    await router.push({ path: '/cs/my-question/write' })
  }

  const fnView = async item => {
    await router.push({
      path: '/cs/my-question/view',
      query: { noticeId: item.noticeId }
    })
  }

  return { ...toRefs(state), fnListFunction, fnWrite, fnView }
}
