<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="notice_list_wrap">
            <div class="list_top">
              <div class="left">
                <div class="total">총 {{ totalCnt }}건</div>
              </div>
              <div class="right"></div>
            </div>
            <div class="notice_list">
              <ul class="notice_list_ul">
                <li
                  class="notice_list_li"
                  v-for="(item, index) in list"
                  :key="index"
                  @click="fnView(item)"
                >
                  <div class="notice_list_ttl">
                    <div class="left">
                      <span class="cate sec_color">{{
                        item.questionType
                      }}</span>
                      <span>{{ item.title }}</span>
                    </div>
                    <div class="right"></div>
                  </div>
                  <div class="notice_list_con">
                    <div class="left">
                      <div class="date">{{ item.regDt }}</div>
                    </div>
                    <div class="right">
                      <span
                        :class="[
                          { grey: item.answerType === '답변대기' },
                          { color: item.answerType === '답변완료' }
                        ]"
                        >{{ item.answerType }}</span
                      >
                    </div>
                  </div>
                </li>
                <observe-visible
                  :key="initCnt"
                  :page="page"
                  :limit="limit"
                  :totalCnt="totalCnt"
                  @fetch="fnListFunction"
                />
              </ul>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn" @click="fnWrite">새로 문의하기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'component-cs-my-question-index',
  setup() {
    const state = componentState()
    return { ...state }
  }
}
</script>

<style scoped></style>
